export const camelCase = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');

export const getActiveLinkStyle = (slug: string, pathname: string) =>
  pathname.endsWith(slug) ? 'underline font-semibold' : '';

export function transformStringToSnakeCase(name: string) {
  return name?.toLowerCase().replace(/\s/g, '_');
}
