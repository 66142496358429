'use client';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { AccordionProps, Accordion as NGGAccordion } from '@ngg/components';
import { cn } from '@ngg/storefront-utils';
import { parseOptions } from '../RichText/RichText';
import { Links } from '@ts/contentful/richText';
import IconComponent from '../icon/icon';

const Icon = ({ open }: any) => (
  <span>
    {open ? (
      <IconComponent name="minus" color="black" size={16} />
    ) : (
      <IconComponent name="plus" color="black" size={16} />
    )}
  </span>
);

type Props = AccordionProps & {
  text?: { links: Links; json: any };
  richText?: boolean;
};

export default function RichTextAccordion({
  children,
  text,
  richText,
  ...props
}: Props) {
  let content = children;
  if (richText && text) {
    content = documentToReactComponents(
      text.json,
      parseOptions({ content: { links: text.links }, ...(props as any) }),
    );
  }
  return (
    <NGGAccordion
      {...props}
      IconComponent={Icon}
      WrapperComponent="div"
      classNames={{
        wrapper: cn(
          'ngg-accordion bg-main border-y border-gray-600 w-full -mt-[1px]',
          props.classNames?.wrapper,
        ),
        button: cn(
          'text-left items-center leading-4 px-0 md:px-4 py-4 md:py-6 font-semibold text-sm',
          props.classNames?.button,
        ),
        panel: cn(
          'pb-5 px-0 md:px-4 pt-0 [&>*]:text-sm [&>p:first-child]:-mt-2 [&>p:last-child]:mb-1',
          props.classNames?.panel,
        ),
      }}>
      {content}
    </NGGAccordion>
  );
}
